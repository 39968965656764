.cardStyle{
    width:150px;
    border-radius:5%;
    border:0.5px solid black;
}

.panierTab{
    border:0.5px solid black;
    width: auto;
    border-radius:5%;
    min-height: 50vh ;
}
.btnCard{
    padding: 1px;
}